<template>
  <b-col
    cols="12"
    md="12"
    class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pull-left"
  >
    <label>Nature </label>
    <v-select
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :value="accountNatureFilter"
      :options="accountNatureOptions"
      class="per-page-selector d-inline-block mx-300"
      style="width: 150px"
      :reduce="(val) => val.value"
      @input="(val) => $emit('update:accountNatureFilter', val)"
    />
  </b-col>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BRow, BCol } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    accountNatureFilter: {
      type: [String, null],
      default: null,
    },
    accountNatureOptions: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
